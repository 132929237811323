

<template>
    <div class="blocTestEntier">
        <div class="conteneurlabelTest">
        <p class="labelTest"> {{ labelTestVideo }} ({{ cheminVideo }}{{ nomVideo }}) </p>
        </div>

        <div class="ensembleAffichageTest">
            <div class="espaceAffichagePlayer"> 
                <button @click="play">Play</button>
                    <button @click="pause">Pause</button>
                    <button @click="stop">Stop</button>
                    <button @click="avanceCinqSecond">+5 sec</button>
                    <button @click="reculeCinqSecond">-5 sec</button>
                    <div>Time: {{ remainingTime }}</div>
            </div>

            <div class="espaceAffichageVideo"> 
                 <!--<button v-if="videoUrl == ''" @click="lancerVideo(cheminVideo+nomVideo)">Load video</button>-->
                    
                    

                    <video ref="videoPlayer" width="640" height="360" @timeupdate="updateTime" @ended="resetTime">
                    <source :src="cheminVideo+nomVideo" type="video/mp4">
                    Your browser does not support the video tag.
                    </video>             
            </div>
                
        </div>
    </div>
</template>


<script>

export default {
props:['labelTestVideo','cheminVideo','nomVideo'],
data() {
    return {
      videoUrl: '',
      sound: null,
      interval: null,
      remainingTime: 0
    };
  },
methods:{
    lancerVideo(inUrlVideo){
        this.videoUrl = inUrlVideo;

    },
    play() {
      this.$nextTick(() => {
        if (this.$refs.videoPlayer) {
        this.$refs.videoPlayer.play();
        }
  });
    },
    pause() {
      this.$refs.videoPlayer.pause();
    },
    stop() {
      this.$refs.videoPlayer.pause();
      this.$refs.videoPlayer.currentTime = 0;
      this.remainingTime = this.totalDuration.toFixed(2);
    },
    avanceCinqSecond() {
      this.$refs.videoPlayer.currentTime += 5;
    },
    reculeCinqSecond() {
      this.$refs.videoPlayer.currentTime -= 5;
    },
    updateTime() {
      this.totalDuration = this.$refs.videoPlayer.duration;
      this.remainingTime = (this.totalDuration - this.$refs.videoPlayer.currentTime).toFixed(2);
    },
    resetTime() {
      this.remainingTime = this.totalDuration.toFixed(2);
    }
  },
  mounted() {
    this.$refs.videoPlayer.addEventListener('loadedmetadata', () => {
      this.totalDuration = this.$refs.videoPlayer.duration;
      this.remainingTime = this.totalDuration.toFixed(2);
    });
  }
}

</script>




<style scoped>

.blocTestEntier {
    width: 100vh;
    height: 30vh;
    padding: 10px;
    position: relative;
    margin: 0;
    background: white;
    border-radius: 0px;
}

.conteneurlabelTest {
    top: 0;
    position: relative;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 20%;
}

.labelTest {
    margin: 0;
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ensembleAffichageTest{
    margin: 0;
    position: relative;
    width: 100%;
    height: 80%;
}

.boutonLancerTest{
    margin: 0;
    float: left;
    width: 30%;
    height: 100%;
}

.espaceAffichagePlayer{
margin: 0;
float: left;
    width: 30%;
    height: 100%;
    overflow: hidden;

}

.espaceAffichageVideo{
margin: 0;
float: left;
    width: 70%;
    height: 100%;
    overflow: hidden;

}

.espaceAffichageVideo video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>