// VideoChat.vue
<template>
  <div class="video-container">
    <div class="local-video">
      <video ref="localVideo" autoplay muted></video>
    </div>
    <div class="remote-video">
      <video ref="remoteVideo" autoplay></video>
    </div>
    <div class="controls">
      <button @click="createRoom" v-if="!roomId">Créer une salle</button>
      <div v-else>
        Lien de la salle: {{ window.location.href }}?room={{ roomId }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'VideoChat',
  setup() {
    const localVideo = ref(null)
    const remoteVideo = ref(null)
    const roomId = ref(null)
    const peerConnection = ref(null)

    const configuration = {
      iceServers: [
        { urls: 'stun:stun.l.google.com:19302' }
      ]
    }

    onMounted(async () => {
      // Vérifier si on rejoint une salle existante
      const urlParams = new URLSearchParams(window.location.search)
      const roomParam = urlParams.get('room')
      if (roomParam) {
        roomId.value = roomParam
        await joinRoom(roomParam)
      }
      
      // Initialiser la vidéo locale
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ 
          video: true, 
          audio: true 
        })
        localVideo.value.srcObject = stream
      } catch (err) {
        console.error("Erreur d'accès à la caméra:", err)
      }
    })

    const createRoom = async () => {
      // Créer un ID unique pour la salle
      roomId.value = Math.random().toString(36).substring(7)
      initializePeerConnection()
    }

    const joinRoom = async (roomId) => {
      initializePeerConnection()
      // Ici, vous devriez implémenter la logique de signalisation
      // pour rejoindre la salle existante
    }

    const initializePeerConnection = () => {
      peerConnection.value = new RTCPeerConnection(configuration)
      
      // Ajouter les tracks locaux
      localVideo.value.srcObject.getTracks().forEach(track => {
        peerConnection.value.addTrack(track, localVideo.value.srcObject)
      })

      // Gérer les tracks distants
      peerConnection.value.ontrack = (event) => {
        remoteVideo.value.srcObject = event.streams[0]
      }

      // Gérer les candidats ICE
      peerConnection.value.onicecandidate = (event) => {
        if (event.candidate) {
          // Envoyer le candidat via votre système de signalisation
        }
      }
    }

    return {
      localVideo,
      remoteVideo,
      roomId,
      createRoom
    }
  }
}
</script>

<style scoped>
.video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.local-video video {
  width: 200px;
  height: 150px;
  margin: 10px;
}

.remote-video video {
  width: 600px;
  height: 450px;
  margin: 10px;
}

.controls {
  margin: 20px;
}
</style>