

<template>
    <div class="blocTestEntier">
        <div class="conteneurlabelTest">
        <p class="labelTest"> {{ labelTestImage }} ({{ cheminImage }}{{ nomImage }}) </p>
        </div>

        <div class="ensembleAffichageTest">
            

            <div class="espaceAffichageImage"> 
                <button v-if="imageUrl == ''" @click="loadImage(cheminImage+nomImage)">Load Image</button>
                <div v-if="loading">Loading image...</div>
                <div class="messageErreur" v-if="messageErreur != ''">{{ messageErreur }}</div>
                <img v-if="imageUrl != '' && !loading" :src="imageUrl" alt="Description of Image">

                
            </div>
                
        </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
props:['labelTestImage','cheminImage','nomImage'],
data() {
    return {
      imageUrl: '',
      messageErreur: '',
      loading: false  // To track loading state
    };
  },
methods:{
async loadImage(inUrlImage) {
      this.loading = true;
      try {
       
       console.log('Chargement image:', inUrlImage);

        let response = await axios.get(inUrlImage);

        this.imageUrl = inUrlImage;
      } catch (error) {
        console.error('Error fetching the image URL:', error);
        this.messageErreur = error;
      } finally {
        this.loading = false;

      }
    }
}

}

</script>




<style scoped>

.blocTestEntier {
    width: 100vh;
    height: 15vh;
    padding: 10px;
    position: relative;
    margin: 0;
    background: white;
    border-radius: 0px;
}

.conteneurlabelTest {
    top: 0;
    position: relative;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 20%;
}

.labelTest {
    margin: 0;
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ensembleAffichageTest{
    margin: 0;
    position: relative;
    width: 100%;
    height: 80%;
}

.boutonLancerTest{
    margin: 0;
    float: left;
    width: 30%;
    height: 100%;
}

.espaceAffichageImage{
margin: 0;
float: left;
    width: 70%;
    height: 100%;
    overflow: hidden;

}

.espaceAffichageImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.messageErreur{

    color: red;
}
</style>