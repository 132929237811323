<template>
  
  <h1> Test prerequis wivy visio </h1>
  <VideoChat />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import AfficheurBlocTestPosition from './components/AfficheurBlocTestPosition.vue'
import AfficheurBlocTestImage from './components/AfficheurBlocTestImage.vue'
import AfficheurBlocTestSon from './components/AfficheurBlocTestSon.vue'
import AfficheurBlocTestVideo from './components/AfficheurBlocTestVideo.vue'
import VideoChat from './components/VideoChat.vue'

@Options({
  components: {
    VideoChat,
    AfficheurBlocTestPosition,
  AfficheurBlocTestImage,
  AfficheurBlocTestSon,
  AfficheurBlocTestVideo
  },
})
export default class App extends Vue {
  data(){
    return {
      //urlServeur : 'http://192.168.1.31:8080/',
      //urlServeur : 'http://localhost:8080/',
      urlServeur : 'https://wivy-famille.fr/',

      repertoireImageQuestion : 'images/',
      nomImageJPEG: 'Image201912711202058603.jpeg',
      nomImagePNG: 'Image20172781340190110.png',

      repertoireSonQuestion : 'sons/',
      nomSonOGG : 'Son201911414631857481.ogg',
      nomSonMP3 : 'sonmptrois.mp3',

      repertoireVideo : 'videos/',
      nomVideoMP4 : 'videoVlibParis.mp4',
      roomId: '12345678'

    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
