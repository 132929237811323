

<template>
    <div class="blocTestEntier">
        <div class="conteneurlabelTest">
        <p class="labelTest"> {{ labelTestSon }} ({{ cheminSon }}{{ nomSon }}) </p>
        </div>

        <div class="ensembleAffichageTest">
            

            <div class="espaceAffichagePlayerSon"> 
                <button v-if="sonUrl == ''" @click="playSound(cheminSon+nomSon)">Load sound</button>
                <div v-if="sonUrl != '' && sound == null">Loading sound...</div>
                <div v-if="sound != null"> lecture son en cours... 
                    <button @click="play">Play</button>
                    <button @click="pause">Pause</button>
                    <button @click="stop">Stop</button>
                    <button @click="avanceCinqSecond">+5 sec</button>
                    <button @click="reculeCinqSecond">-5 sec</button>
                    <div>Time: {{ currentTime }}</div>

                </div>

                
            </div>
                
        </div>
    </div>
</template>


<script>
import { Howl } from 'howler';

export default {
props:['labelTestSon','cheminSon','nomSon'],
data() {
    return {
      sonUrl: '',
      sound: null,
      interval: null,
      currentTime: 0
    };
  },
methods:{
playSound(inUrlSon) {
    this.sonUrl = inUrlSon;
      if (!this.sound) {
        this.sound = new Howl({
          src: [inUrlSon]
        });
      }
      this.sound.play();
      this.startTrackingTime();
    },
    play() {
      this.sound.play();
      this.startTrackingTime();
    },
    pause() {
      this.sound.pause();
      this.stopTrackingTime();
    },
    stop() {
      this.sound.stop();
      this.resetTime();
      this.stopTrackingTime();
    },
    startTrackingTime() {
      if (this.interval) return;

      this.interval = setInterval(() => {
        this.currentTime = (this.sound.duration() - this.sound.seek() || 0).toFixed(2);
      }, 100);
    },
    stopTrackingTime() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    resetTime() {
      this.currentTime = (this.sound.duration()).toFixed(2);
    },
  beforeDestroy() {
    this.stopTrackingTime();
    if (this.sound) {
      this.sound.unload();
    }
  },
  avanceCinqSecond(){
    if (this.sound && this.sound.playing()) {
        let current = this.sound.seek();  // Get current playback time
        this.sound.seek(current + 5);     // Move forward by 5 seconds
    }
  },
  reculeCinqSecond(){
    if (this.sound && this.sound.playing()) {
        let current = this.sound.seek();  // Get current playback time
        this.sound.seek(current - 5);     // Move forward by 5 seconds
    }
  }
}
}

</script>




<style scoped>

.blocTestEntier {
    width: 100vh;
    height: 15vh;
    padding: 10px;
    position: relative;
    margin: 0;
    background: white;
    border-radius: 0px;
}

.conteneurlabelTest {
    top: 0;
    position: relative;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 20%;
}

.labelTest {
    margin: 0;
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ensembleAffichageTest{
    margin: 0;
    position: relative;
    width: 100%;
    height: 80%;
}

.boutonLancerTest{
    margin: 0;
    float: left;
    width: 30%;
    height: 100%;
}

.espaceAffichagePlayerSon{
margin: 0;
float: left;
    width: 70%;
    height: 100%;
    overflow: hidden;

}
</style>