<template>
<div class="blocTestEntier">
        <div class="conteneurlabelTest">
        <p class="labelTest"> test recuperation position </p>
        </div>

        <div class="ensembleAffichageTest">
            <button v-if="positionTexte == ''" @click="requestPosition()">get position</button>
            <button v-if="latitude != 0 || longitude != 0" @click="openPositionOnGoogleMaps()">verifier la position sur map</button>
            <div v-if="error" class="error-message">{{ error }}</div>   
            <div v-if="positionTexte != ''" class="affichagePositionDevice"> Position device : {{ positionTexte }}</div>     
        </div>
    </div>
  <div>
    
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: null,
      positionTexte: '', 
      latitude: 0,
      longitude: 0
    };
  },
  methods: {
    requestPosition() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.successCallback, this.errorCallback);
      } else {
        this.error = 'Geolocation is not supported by this browser.';
      }
    },
    openPositionOnGoogleMaps() {

      // Create the Google Maps URL
      const googleMapsUrl = `https://www.google.com/maps/?q=${this.latitude},${this.longitude}`;

      // Open the URL in a new browser tab
      window.open(googleMapsUrl, '_blank');
    },
    successCallback(position) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      this.positionTexte = `Latitude: ${this.latitude}, Longitude: ${this.longitude}`;
      this.error = null; // Clear error
    },
    errorCallback(error) {
      if (error.code === error.PERMISSION_DENIED) {
        this.error = 'Please enable location services for this site.';
      } else {
        this.error = `ERROR(${error.code}): ${error.message}`;
      }
    }
  }
};
</script>

<style>
.blocTestEntier {
    width: 100vh;
    height: 15vh;
    padding: 10px;
    position: relative;
    margin: 0;
    background: white;
    border-radius: 0px;
}

.conteneurlabelTest {
    top: 0;
    position: relative;
    background: rgba(0,0,0,0.5);
    width: 100%;
    height: 20%;
}

.labelTest {
    margin: 0;
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ensembleAffichageTest{
    margin: 0;
    position: relative;
    width: 100%;
    height: 80%;
}

.error-message {
  color: red;
}

.affichagePositionDevice{

    color: black;
    font-size-adjust: 3em;
}
</style>
